<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">话题圈</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <pullrefresh :disabled="true" @onLoad="onLoad" ref="pull" v-show="topiclist.length>0">
          <router-link tag="div" :to="{path:'/topicCircleInfo',query:{name:item.name}}" class="img" v-for="item in topiclist" :key="item.id">
            <ImgDecypt class="img-ht" :src="item.cover"/>
            <div class="sec flex-column">
              <div class="title"><span class="htq"></span>{{item.name}}</div>
              <div class="sec-w">{{item.desc}}</div>
              <div class="num flex-center">
                <div class="sec-left">帖子{{item.mediaNum|watchCount}}</div>
                <div class="sec-left">预览量 {{item.watches|watchCount}}</div>
              </div>
            </div>
          </router-link>
        </pullrefresh>
        <van-loading type="spinner" color="#f21313" v-show="topiclist&&topiclist.length==0 && !noData"/>
        <nodata v-show="noData"/>
      </div>
    </div>
  </div>
</template>
<script>
import {getTopiclist} from '@/api/app.js';
import pullrefresh from "@/components/PullRefresh/index.vue";
export default {
  components:{
    pullrefresh
  },
  data(){
    return{
      topiclist:[],//话题圈列表
      noData:false,//暂无数据
    }
  },
  created(){
    this.getTopiclist();
  },
  methods:{
    async getTopiclist(){//获取话题列表
      let req = {
        pageNum:1,
        pageSize:10,
      }
      let ret = await this.$Api(getTopiclist,req);
      if(!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if(ret.code == 200){
        let list = ret.data.topicInfos?ret.data.topicInfos:[]
        this.topiclist = this.topiclist.concat(list);
        if(list.length < 10){
          this.$refs.pull.finished = true;
        }else{
          this.$refs.pull.finished = false;
        }
        if(this.pageNum == 1 && list.length == 0){
          this.noData = true;
        }
      }
    },
    onLoad(){
      this.pageNum ++;
      this.getTopiclist();
    }
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
  color:#ffffff;
  font-size:14px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.img{
  height:119px;
  margin-top:10px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
.img-ht{
  width:100%;
  height: 100%;
}
.sec{
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:rgba($color: #000000, $alpha:0.4);
  align-items: center;
  justify-content: center;
  color:#ffffff;
}
.htq{
  display: inline-block;
  width:22px;
  height:22px;
  background:url('../../assets/png/ht_quan.png') center center no-repeat;
  background-size:100%;
  margin-right:10px;
  vertical-align: -6px;
}
.title{
  font-size:13px;
}
.sec-w{
  font-size:10px;
  margin-top:9px;
}
.num{
  width:100%;
  margin-top:12px;
  height:28px;
  line-height: 28px;
  font-size:10px;
  color:#8e8b8b;
}
.sec-left{
  flex:1;
}
.sec-left:nth-child(1){
  padding-right:12px;
  text-align: right;
  border-right:1px solid #8e8b8b;
}
.sec-left:nth-child(2){
  padding-left:12px;
}
</style>
